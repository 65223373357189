import React, { Component } from 'react'
import Variables from "./Variables";
import Header1 from './Header1';

export class CountDown extends Component {
  variables = Variables;
  imgUrls = [];
  imgDownloadingIndex = 0;
  
    constructor(props) {
      super(props);
    
      // This binding is necessary to make `this` work in the callback
      this.state = {
        timeLeft: 4,
        questionDownloaded: false,
        questionImage: ""
      };

      this.startCountDown = this.startCountDown.bind(this);
      this.beginCountDown = this.beginCountDown.bind(this);
      this.StartPlaying = this.StartPlaying.bind(this);
        
    }
    
    StartPlaying(e) {
      clearInterval(this.intervalHandle);
      this.props.onChange(4);
    } 

    beginCountDown() {
        let timeLeft = this.state.timeLeft - 1;
        if(timeLeft <= 0){
            this.StartPlaying();
            return;
        }
        this.setState({timeLeft: timeLeft});
    }

    startCountDown() {
        this.setState({
          questionDownloaded: true
        });
        this.intervalHandle = setInterval(this.beginCountDown, 1000);
    }

    downloadQuestion(){
      var tags = encodeURIComponent(this.variables.brand.tags);
      var url = this.variables.apiBaseUrl + 'getQuestions/' + this.variables.token + "/" + this.variables.questionLimit
      if(tags !== ""){
        url += "/" + tags
      }
      fetch(url, {
        mode: 'cors'
      }).then(results => {
        return results.text();
      }).then(data => {
        var xdata = JSON.parse(this.handShake(data));
        this.variables.questions = xdata.data;
        this.variables.questionIndex = 0;
        this.downloadImages();
      })
    }

    handShake(data){
      var xdata = data.split(".")
      var xxdata = atob(xdata[0]);
      var xhx = xdata[1].split('').reverse().join('');
      var h = atob(xhx);

      var content = this.whatgoesaroundcomesaround(xxdata, h);
      return content;
    }

    whatgoesaroundcomesaround (s, k) {
        var enc = "";
        var str = s.toString();
        for (var i = 0; i < str.length; i++) {
          var a = str.charCodeAt(i);
          var b = a ^ k;
          enc = enc + String.fromCharCode(b);
        }
        return enc;
    }

    downloadImages() {
      for (let d of this.variables.questions) {
        let question = d.question;
        var matches = question.match(/\bhttps?:\/\/\S+/gi);
        matches = (matches == null) ? [] : matches;
        if(matches.length > 0){
          let imgurl = matches[0];
          if(this.imgUrls.indexOf(imgurl) === -1)
            this.imgUrls.push(imgurl);
        }
      }

      if(this.imgUrls.length > 0){
        this.downloadImage(this.imgUrls[0])
      }else{
        this.startCountDown();
      }
    }

    downloadImage(url){
      this.setState({
        questionImage: url
      })
    }

    handleImageLoaded = (e) => {
      this.imgDownloadingIndex += 1
      if(this.imgDownloadingIndex >= this.imgUrls.length){
        this.startCountDown();
        return;
      }
      this.downloadImage(this.imgUrls[this.imgDownloadingIndex]);
    }

    handleImageErrored = (e) => {
      this.imgDownloadingIndex += 1
      if(this.imgDownloadingIndex >= this.imgUrls.length){
        this.startCountDown();
        return;
      }
      this.downloadImage(this.imgUrls[this.imgDownloadingIndex]);
    }

    componentDidMount(){
      this.downloadQuestion();
    }

  render() {
    if(!this.state.questionDownloaded)
    return(
    <div className="fadeinanime">
      <Header1 />
      <div className="center-div mt100">
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <h3 className="white mt20">Downloading questions...</h3>
      { this.state.questionImage.length > 0 && 
      <img alt="" width="1" height="1"
          src={this.state.questionImage}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageErrored}
        />
      }
      </div>
    </div>
    )

    return (
      <div className="center-div">
        <h1 className="text200 white">{this.state.timeLeft}</h1>
        <h1 className="white">Ready!!!</h1>
      </div>
    )
  }
}

export default CountDown
