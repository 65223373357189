import React, { Component } from 'react'
import Variables from "./Variables";
import { AwesomeButton } from 'react-awesome-button';
import  'react-awesome-button/dist/themes/theme-rickiest.css';
import WhatsAppIcon from '../images/whatsapp2.png'

export class YouLost extends Component {
    variables = Variables;
    state = {
      pollSubmitted: false,
    };
  
    PlayAgain = (e) => {
      this.props.onChange(2);
      // var brandid = this.variables.brand.id;
      // var mobileNo = '%2b233' + this.variables.mobileNo.substr(1, this.variables.mobileNo.length);
      //   fetch(this.variables.apiBaseUrl + 'genBrandToken/' + mobileNo + '/' + brandid, {mode: 'cors'})
      //   .then(results => {
      //       return results.json();
      //   }).then(data => {
      //       if(data.hasOwnProperty('success')){
      //           this.variables.token = data.token;
      //           this.variables.playCount = parseInt(data.lives);
      //           this.variables.maxPlayCount = parseInt(data.max_lives);

      //           this.variables.playCount +=1;
      //           if(this.variables.pollIndex < this.variables.polls.length)
      //             this.variables.pollIndex +=1;
      //           this.props.onChange(3);
      //       }else{
      //           this.setState({
      //               popup: 3
      //           });
      //       }
      //   })
    }

    RestartEyeBlinking = (e) =>{
        var rightTearDrop = document.getElementsByClassName("teardrop1");
        for (let drop of rightTearDrop) {
            drop.classList.remove("teardrop1");
            void drop.offsetWidth;
            drop.classList.add("teardrop1");
        }

        rightTearDrop = document.getElementsByClassName("teardrop2");
        for (let drop of rightTearDrop) {
            drop.classList.remove("teardrop2");
            void drop.offsetWidth;
            drop.classList.add("teardrop2");
        }

        rightTearDrop = document.getElementsByClassName("teardrop3");
        for (let drop of rightTearDrop) {
            drop.classList.remove("teardrop3");
            void drop.offsetWidth;
            drop.classList.add("teardrop3");
        }

        var eyes = document.getElementsByClassName("blink2");
        for (let eye of eyes) {
            eye.classList.remove("blink2");
            void eye.offsetWidth;
            eye.classList.add("blink2");
        }
    }

    SubmitSurvey = (e) => {
      let pollIndex = this.variables.pollIndex;
      let span = e.getElementsByClassName("aws-btn__content");
      let selectedAns = span[0].innerText.trim();
      var pollId = this.variables.polls[pollIndex].id
      var mobileno = this.variables.mobileNo;

      fetch(this.variables.apiBaseUrl + 'submitPoll', {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          poll: pollId,
          answer: selectedAns,
          mobileno: mobileno
        }),
        
      }).then(results => {
        return results.json();
      }).then(data => {
        
      })

      this.setState({
        pollSubmitted: true,
      })
    }

  render() {
    return (
        <div className="center-div max400">
          <div className="circleBase type2 center-div">  
                <div className="clear-fix inline mt25">
                    <div className="blink2 eye float-left" onAnimationEndCapture={this.RestartEyeBlinking}></div>
                    <div className="blink2 eye float-left ml20"></div>
                </div>
                <div className="clear-fix pl25 pr25">
                    <div className="fade teardrop1 arrow-up float-left"></div>
                    <div className="fade teardrop1 arrow-up float-left ml35"></div>
                </div>
                <div className="clear-fix pl25 pr25">
                    <div className="fade teardrop2 arrow-up float-left"></div>
                    <div className="fade teardrop3 arrow-up float-left ml35"></div>
                </div>
            </div>
          <div className="mt-40">
              <div className="mouth"></div>
            </div>

          {this.variables.showAnswer === 1 && 
            <h2 className="white mt0">Correct answer is "<b><u>{this.variables.answer}</u></b>"</h2>
          }
          {this.variables.showAnswer === 0 && 
            <h2 className="white mt0">Oops! wrong answer</h2>
          }

        
        {(this.variables.polls.length === 0 || this.state.pollSubmitted) && 
        <>
        <div className="text16"></div>
          <AwesomeButton className="startPlaying option" type="secondary" 
                    href={this.variables.shareUrl} target="_blank">
                    <img alt="Tell you friends on whatsapp" src={WhatsAppIcon} className="iconImg" />
                    <span>Share on Whatsapp</span>
          </AwesomeButton>

          <div className="text16"></div>
          <AwesomeButton className="startPlaying option" type="secondary" 
                    href={this.variables.facebookShareUrl} target="_blank">
                    <span>Share on Facebook</span>
          </AwesomeButton>

          <div className="text16"></div>
          <AwesomeButton className="startPlaying option" type="secondary" 
                    href={this.variables.twitterShareUrl} target="_blank">
                    <span>Share on Twitter</span>
          </AwesomeButton>

          <div className="text16"></div>
          {this.variables.maxPlayCount > this.variables.playCount &&
            <AwesomeButton className="startPlaying option" type="secondary" action={this.PlayAgain}>
                Play Again
            </AwesomeButton>
          }
        </>
        }

        {this.variables.polls.length > 0 && this.variables.polls[this.variables.pollIndex].id !== "0" && !this.state.pollSubmitted &&
        <>
            <p className="center-div mt40 white max400 text20">
              <u>Quick Poll</u><br/>
              {this.variables.polls[this.variables.pollIndex].question}
            </p>
            <div className="center-div mt10">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
                {this.variables.polls[this.variables.pollIndex].optionA}
              </AwesomeButton>
            </div>
            <div className="center-div">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
              {this.variables.polls[this.variables.pollIndex].optionB}
              </AwesomeButton>
            </div>
            {this.variables.polls[this.variables.pollIndex].optionC.length > 0 &&
                <div className="center-div">
                <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
                {this.variables.polls[this.variables.pollIndex].optionC}
                </AwesomeButton>
                </div>
            }
            {this.variables.polls[this.variables.pollIndex].optionD.length > 0 &&
            <div className="center-div">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
              {this.variables.polls[this.variables.pollIndex].optionD}
              </AwesomeButton>
            </div>
            }
            </>
        }

        </div>
      )
  }
}

export default YouLost
