import React, { Component } from 'react'


export class Processing extends Component {

  render() {
    if(!this.props.visible)
        return true;
    
    return (
        <div className="processingDiv">
            <div id="content">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <h3 className="white mt20">Processing...</h3>
            </div>
        </div>
    )
  }
}

export default Processing
