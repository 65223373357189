var Variables = {
    timeLeft: 0,
    showAnswer: 1,
    questionLimit: 5,
    gameTime: 50,
    timeTaken: 0,
    playCount: 0,
    maxPlayCount: 3,
    brandStartTime: '',
    winningCode: '',
    network: "mtn",
    mobileNo: "",
    answer: "",
    brands: [],
    polls: [],
    pollIndex: 0,
    winningAmount: "GHS2,500",
    selectedAns: [],
    questions: [],
    selectNetwork: false,
    continuePlay: false,
    questionIndex: 0,
    pauseCountDown: false,
    totalBrainwavePoint: 0,
    brandApiKey: "da4b9237bacccdf19c0760cab7aec4a8359010b0",
    apiBaseUrl: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
                "https://apps.mobivs.com/services/triv5_brands/APPS/public/api/trivFive/": 
                "https://apps.mobivs.com/services/triv5_brands/APPS/public/api/trivFive/",
    subscriptionApiBase: "https://apps.mobivs.com/services/triv5_brands/APPS/public/api/v1/",
    shareUrl: "whatsapp://send?text=$$description$$. $$url$$",
    facebookShareUrl: "https://www.facebook.com/sharer/sharer.php?u=$$url$$",
    twitterShareUrl: "https://twitter.com/share?text=$$description$$&url=$$url$$",
};
export default Variables;