import React, { Component } from 'react'
import Confetti from 'react-confetti'
import Variables from "./Variables";
import { AwesomeButton } from 'react-awesome-button';
import  'react-awesome-button/dist/themes/theme-rickiest.css';
import Header1 from './Header1';

export class YouWon extends Component {
  variables = Variables;
  state = {
    pollSubmitted: false,
  };

  SubmitSurvey = (e) => {
    let span = e.getElementsByClassName("aws-btn__content")
      let selectedAns = span[0].innerText.trim()
      var pollId = this.variables.polls[this.variables.pollIndex].id
      var mobileno = this.variables.mobileNo

      fetch(this.variables.apiBaseUrl + 'submitPoll', {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          poll: pollId,
          answer: selectedAns,
          mobileno: mobileno
        }),
        
      }).then(results => {
        return results.json();
      }).then(data => {
        
      })

      this.setState({
        pollSubmitted: true,
      })
  }

  componentWillMount(){
    this.variables.pollIndex = (this.variables.pollIndex >= this.variables.polls.length) ? 0 : this.variables.pollIndex;
  }

  render() {
    return (
      <>
      <Confetti className={(this.variables.polls.length === 0 || this.state.pollSubmitted) ? "" : "hide"} />
      <div className="fadeinanime">
        <Header1 />
        <div className="center-div max400 aboveConfetti">
          <p className="white text30 mb0">Congratulations! You have won a {this.variables.potentialData} voucher.</p>
          
          {(this.variables.polls.length === 0 || this.state.pollSubmitted) && 
          <>
            <p className="mt40 white text30">Your winning code is <br/><span>{this.variables.winningCode}</span></p>
            <p className="white">Visit any {this.variables.brandApiKey.name} branch to redeem your reward</p>
          </>
          }
          {(this.variables.polls.length > 0 && !this.state.pollSubmitted) && 
            <>
            <p className="center-div mt40 white max400 text30">
              <u>Quick Poll</u><br/>
              {this.variables.polls[this.variables.pollIndex].question}
            </p>
            <div className="center-div mt10">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
                {this.variables.polls[this.variables.pollIndex].optionA}
              </AwesomeButton>
            </div>
            <div className="center-div">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
              {this.variables.polls[this.variables.pollIndex].optionB}
              </AwesomeButton>
            </div>
            {this.variables.polls[this.variables.pollIndex].optionC.length > 0 &&
                <div className="center-div">
                <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
                {this.variables.polls[this.variables.pollIndex].optionC}
                </AwesomeButton>
                </div>
            }
            {this.variables.polls[this.variables.pollIndex].optionD.length > 0 &&
            <div className="center-div">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.SubmitSurvey}>
              {this.variables.polls[this.variables.pollIndex].optionD}
              </AwesomeButton>
            </div>
            }
            </>
          }
           
        </div>
      </div>
      </>
    )
  }
}

export default YouWon
