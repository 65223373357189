import React, { Component } from 'react'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import Header1 from './Header1';
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
  import SubscribePopup from "./PopupSubscribe";
  import SubSentPopup from "./SubscriptionSent";
  import LimitReachedPopup from "./LimitReachedPopup";
  import Variables from "./Variables";
  import Processing from "./Processing";
  import cookie from 'react-cookies'
import WeAreClosed from './WeAreClosed';

export class EnterMobileNo extends Component {
    variables = Variables;
    constructor(props){
        super(props);
        this.state = {
            processing: false,
            isProcessed: false,
            popup: 0
        };
    }
    componentDidMount = (e) =>{
        let tel = document.getElementById("txTel");
        tel.value = this.variables.mobileNo;
        tel.focus();
    }
    GetNumber = (e) => {
        this.variables.mobileNo = e.target.value;
    }
    GenerateToken(){
        var mobileNo = '%2b233' + this.variables.mobileNo.substr(1, this.variables.mobileNo.length);
        var brandid = this.variables.brand.id;
        fetch(this.variables.apiBaseUrl + 'genBrandToken/' + mobileNo + "/" + brandid, {mode: 'cors'})
        .then(results => {
            return results.json();
        }).then(data => {
            if(data.hasOwnProperty('success')){
                if(data.hasOwnProperty('timeleft')){
                    //event will begin soon
                    this.variables.brandStartTime = data.timeleft;
                    this.setState({
                        popup: 4
                    });
                }else{
                    this.variables.token = data.token;
                    this.variables.playCount = parseInt(data.lives);
                    this.variables.maxPlayCount = parseInt(data.max_lives);

                    this.props.onChange(3);
                }
            }else{
                this.setState({
                    popup: 3
                });
            }
        })
    }
    UpdateCookie(mobileno){
        var date = new Date();
        var midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        this.variables.cookiedata = typeof(this.variables.cookiedata) === "undefined" ? [] : this.variables.cookiedata
        var count = this.variables.cookiedata.length;
        
        if(count <= this.variables.cookieIdentityCount){
            if(this.variables.cookiedata.includes(mobileno)){
                return true
            }else{
                if(count < this.variables.cookieIdentityCount){
                    this.variables.cookiedata.push(mobileno)
                    cookie.save('_btd', this.variables.cookiedata, { path: '/', expires: midnight })
                    return true
                }
            }
        }
        
        return false;
    }
    SubscribeMobileNo = () => {
        this.setState({
            processing: true
        })
        
        var mobileNo = '%2b233' + this.variables.mobileNo.substr(1, this.variables.mobileNo.length);
        var network = this.variables.network;
        
        var url = this.variables.subscriptionApiBase + 'subscribe/' + mobileNo + "/" + this.variables.brand.name
        if(this.variables.selectNetwork){
            url = this.variables.subscriptionApiBase + 'subscribe/' + network + '/' + mobileNo + this.variables.brand.name
        }

        fetch(url, {mode: 'cors'})
            .then(results => {
                return results.json();
            }).then(data => {
                this.SaveMobileNo();
            })
    }
    SaveMobileNo = () => {
        if(this.variables.mobileNo.length === 10 && this.variables.network !== ""){
            this.setState({
                processing: true,
            });
            var maxAge = 60 * 1000 * 24 * 7 //cookie max age set to a week
            cookie.save('bw_brand_user', Variables.mobileNo, { path: '/', maxAge: maxAge });
            var mobileNo = '%2b233' + this.variables.mobileNo.substr(1, this.variables.mobileNo.length);
            var url = this.variables.subscriptionApiBase + 'getSubStatus/' + mobileNo
            fetch(url, {mode: 'cors'})
                .then(results => {
                return results.json();
                }).then(data => {
                    this.setState({
                        isProcessed: true,
                        processing: false
                    });
                    
                    if(data.status){
                        this.GenerateToken();
                    }else{
                        this.SubscribeMobileNo();
                    }
                })
        }
    }
    PreventPasting = (event) => {
        event.preventDefault();
    }
    ValidateMobileNo = (event) => {
        let key = event.key;
        
        if(key === " "){
            event.preventDefault();
        }
        if(key === "Enter" || key === "Backspace" || !isNaN(key)){
            return true;
        }else{
            event.preventDefault();
        }
    }
    ChangePopup = (value) => {
        this.setState({
            popup: value
        });
        if(this.variables.continuePlay){
            this.SaveMobileNo();
        }
    }

  render() {
    return (
        <>

      <div className="fadeinanime mt20">
        <Header1/>
        <div className="center-div max400">
            
            <div className="white">
                <h1>It's {this.variables.brand.name} trivia challenge</h1>
                <p>Answer 5 easy peezy questions and win amazing rewards from {this.variables.brand.name}</p>
            </div>
            
            <h3 className="mt50 white">
                Enter your mobile number to begin
            </h3>

            <div>
                <input type="tel" autoComplete="off" className="text30 text-center mt0" maxLength="10" onPaste={this.PreventPasting}
                onKeyPress={this.ValidateMobileNo} onChange={this.GetNumber} id="txTel"
                disabled = {(this.state.processing)? "disabled" : ""} />
            </div>

            <div className="mt40">
                <AwesomeButton className="startPlaying" type="secondary" action={this.SaveMobileNo} 
                    disabled = {(this.state.processing)? "disabled" : ""} name="tel">
                    {(this.state.processing)? "Processing..." : "Start Playing"}
                </AwesomeButton>
            </div>
        </div>

        <Processing visible={this.state.processing} />

        {this.state.popup === 1 && 
        <SubscribePopup onChange={this.ChangePopup} />
        }

        {this.state.popup === 2 && 
        <SubSentPopup onChange={this.ChangePopup} />
        }
        
        {this.state.popup === 3 && 
        <LimitReachedPopup onChange={this.ChangePopup} />
        }

        {this.state.popup === 4 && 
        <WeAreClosed onChange={this.ChangePopup} />
        }
      </div>
      </>
    )
  }
}

export default EnterMobileNo
