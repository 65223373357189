import React, { Component } from 'react'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  
  import Variables from "./Variables";
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
  import Processing from "./Processing";
import Header1 from './Header1';


export class Question extends Component {
    variables = Variables;
    constructor(props){
        super(props);

        this.state = {
            timeLeft: this.variables.gameTime,
            questionIndex: 0,
            imgQuestion: '',
            textQuestion: '',
            questions: [],
            choices: [],
            dataToGet: '1GB',
            selectedAns: {},
            mobileNo: this.variables.mobileNo,
            processing: false,
            canAnswer: false,
          };
    }

    handshakeCheck(){
      var str = this.state.questions[this.state.questionIndex].token.trim()
      var h = this.state.questions[this.state.questionIndex].h
      var xstr = atob(str);

      var content = this.whatgoesaroundcomesaround(xstr, h);
      console.log(content);
      return content;
    }

    whatgoesaroundcomesaround (s, k) {
      var enc = "";
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }

    NextQuestion = (e) => {
      let questionSection = document.getElementById("questionSection");
      questionSection.classList.add("fadeoutanime");
      let span = e.getElementsByClassName("aws-btn__content");
      let selectedAns = span[0].innerText.trim();
      let answerObj = this.state.selectedAns;
      let questionId = this.state.questions[this.state.questionIndex].id;
      answerObj[questionId] = selectedAns;
      this.variables.selectedAns = answerObj
      this.setState({
        selectedAns: answerObj
      });
      
      
      if(this.handshakeCheck() !== selectedAns+this.state.questions[this.state.questionIndex].question ){
        this.endGame();
        return;
      }
    }

    stopWorker() { 
      this.variables.worker.terminate();
      this.variables.worker = undefined;
    }

    startWorker() {
      if(typeof(Worker) !== "undefined") {
        if(typeof(this.variables.worker) == "undefined") {
          this.variables.worker = new Worker("countdown_worker.js");
        }
        this.variables.worker.onmessage = ((event) => {
          this.updateCountDown(event.data)
        });
      }
      return false
    }

    endGame() {
        clearInterval(this.intervalHandle);
        this.stopWorker();
        var optionA = this.state.questions[this.state.questionIndex].optionA;
        var optionB = this.state.questions[this.state.questionIndex].optionB;
        var optionC = this.state.questions[this.state.questionIndex].optionC;
        var optionD = this.state.questions[this.state.questionIndex].optionD;
        var question = this.state.questions[this.state.questionIndex].question;

        this.variables.question = question;
        if(this.handshakeCheck() === optionA+question){
          this.variables.answer = optionA;
        }
        if(this.handshakeCheck() === optionB+question){
          this.variables.answer = optionB;
        }
        if(this.handshakeCheck() === optionC+question){
          this.variables.answer = optionC;
        }
        if(this.handshakeCheck() === optionD+question){
          this.variables.answer = optionD;
        }
      
        this.variables.isWon = false
        this.submitSelection();
    }

    endGameSuccess(){
      clearInterval(this.intervalHandle);
      this.stopWorker();
      let timeUsed = this.variables.gameTime - this.state.timeLeft;
      this.variables.timeUsed = timeUsed

      this.variables.isWon = true
      this.submitSelection();
    }

    submitSelection(){
      this.setState({
        processing: true
      })
      let selectedAns = this.state.selectedAns;
      let timeUsed = this.variables.gameTime - this.state.timeLeft;
      
        fetch(this.variables.apiBaseUrl + 'checkBrandAnswers/' + this.variables.token + "/" + timeUsed, {
          mode: 'cors',
          method: 'POST',
          body: JSON.stringify(selectedAns),
          
        }).then(results => {
          return results.json();
        }).then(data => {
          this.variables.winningCode = data.data.code;
          if(this.variables.isWon){
            this.props.onChange(6);
          }else{
            this.props.onChange(5);
          }
        })
    }

    updateCountDown = (timeLeft) => {
      this.setState({timeLeft: timeLeft});

        let dataOptions = [
          {
            minSec: 1,
            maxSec: 10,
            reward: 'GHS50',
          },{
            minSec: 11,
            maxSec: 29,
            reward: 'GHS30',
          },{
            minSec: 30,
            maxSec: 39,
            reward: 'GHS20',
          },{
            minSec: 40,
            maxSec: 50,
            reward: 'GHS10',
          },
        ];

        var timeUsed = this.variables.gameTime - timeLeft;
        dataOptions.map((data) => {
          if(this.isBetween(timeUsed, data.minSec, data.maxSec)){
            this.setState({dataToGet: data.reward});
            return true;
          }

          this.variables.potentialData = data.reward
          this.variables.potentialTime = timeUsed - data.maxSec;

          return false;
        });

        if(timeLeft <= 0){
            this.endGame();
        }
    }

    startCountDown = () => {
        //this.intervalHandle = setInterval(this.beginCountDown, 1000);
        this.startWorker()
    }

    isBetween(n, a, b) {
      return (n - a) * (n - b) <= 0
    }

   RemoveFadeOut = (e) =>{
    this.setState({canAnswer: true})

     let ele = e.target;
     ele.classList.remove("fadeoutanime");
    void ele.offsetWidth;

    let newIndex = this.state.questionIndex + 1;
      if(newIndex >= this.state.questions.length){
            this.endGameSuccess();
      }else{
            this.setState({questionIndex: newIndex});
            this.variables.questionIndex = newIndex;
            this.downloadImagesForQuestion();
      }

   }

   pauseCountDown(){
     this.variables.pauseCountDown = true;
   }

  continueCountDown(){
    this.variables.pauseCountDown = false;
  }

   downloadImagesForQuestion(){
      this.pauseCountDown();
      let question = this.variables.questions[this.variables.questionIndex].question;
      var matches = question.match(/\bhttps?:\/\/\S+/gi);
      matches = (matches == null) ? [] : matches;
      this.setState({
        "imgQuestion": (matches.length > 0) ? matches[0] : "",
        "textQuestion": (matches.length > 0) ? question.replace(matches[0], '') : question,
      });
      if(matches.length > 0){
        let thisObj = this;
        setTimeout(this.IsImageOk, 500, thisObj);
      }else{
        this.continueCountDown();
      }
  }

  shuffle(array) {
    array.sort(() => Math.random() - 0.5);
    return array;
  }

  componentDidMount() {
      if(this.variables.questions.length > 0){
        this.setState({questions: this.variables.questions});
        this.downloadImagesForQuestion();
        this.setState({canAnswer: true});
        this.startCountDown();

        let answerObj = {};
        for (let d of this.variables.questions) {
          let questionId = d.id;
          answerObj[questionId] = null;
          
          //shuffle options
          if(d.optionC.length > 0 && d.optionD.length > 0){
            let arr = [d.optionA, d.optionB, d.optionC, d.optionD];
            arr = this.shuffle(arr);

            d.optionA = arr[0];
            d.optionB = arr[1];
            d.optionC = arr[2];
            d.optionD = arr[3];
          }
        }
        this.setState({
          selectedAns: answerObj
        });
      }
  }

  IsImageOk(thisObj) {
    let img = document.getElementById("img-question");
    if (!img.complete || img.naturalWidth === 0) {
      setTimeout(thisObj.IsImageOk, 500, thisObj);
      return;
    }

    thisObj.continueCountDown();
  }


  render() {
    if(this.state.questions.length === 0 || !this.state.canAnswer)
        return false;

    return (
      <div>
        <Processing visible={this.state.processing} />
        <Header1 />
        <div id="questionSection" onAnimationEndCapture={this.RemoveFadeOut}>
          <div className="center-div ">
            <div className="circleBase type1 center-div"> <span>{this.state.timeLeft}</span> </div>
            <div className="white text-center mt10 l-s-1 hide">{this.state.dataToGet}</div>
          </div>
          <h2 className="center-div mt40 white max400">
              {this.state.textQuestion}
              {this.state.imgQuestion.length > 0 && 
              <div className="white-bg mt20">
                <img alt="Question" id="img-question" src={this.state.imgQuestion} />
              </div>
              }
          </h2>
          <div className="center-div mt40">
            <AwesomeButton className="startPlaying option" type="secondary" action={this.NextQuestion}>
                  {this.state.questions[this.state.questionIndex].optionA}
            </AwesomeButton>
          </div>
          <div className="center-div">
            <AwesomeButton className="startPlaying option" type="secondary" action={this.NextQuestion}>
                  {this.state.questions[this.state.questionIndex].optionB}
            </AwesomeButton>
          </div>
          {this.state.questions[this.state.questionIndex].optionC.length > 0 &&
              <div className="center-div">
              <AwesomeButton className="startPlaying option" type="secondary" action={this.NextQuestion}>
              {this.state.questions[this.state.questionIndex].optionC}
              </AwesomeButton>
              </div>
          }
          {this.state.questions[this.state.questionIndex].optionD.length > 0 &&
          <div className="center-div">
            <AwesomeButton className="startPlaying option" type="secondary" action={this.NextQuestion}>
            {this.state.questions[this.state.questionIndex].optionD}
            </AwesomeButton>
          </div>
          }
          </div>
      </div>
    )
  }
}

export default Question
