import React, { Component } from 'react'
import BrainwaveLogo from '../images/brainwave-logo2.jpg';
import Variables from "./Variables";

export class Header1 extends Component {
  variables = Variables;
  goBack = (e) => {
    if(this.isFunction(this.props.onChange))
      this.props.onChange()
  }
  
  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
  render() {
    return (
      <div>
        <div className="center-div">
          <img alt="BrainWave - A trivia game service" src={BrainwaveLogo} className="logo"  />
          {this.variables.brand && 
          <img alt={this.variables.brand.name} src={this.variables.brand.image_url} className="logo2 ml10" />
          }
        </div>
      </div>
    )
  }
}

export default Header1
