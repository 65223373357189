import React, { Component } from 'react'
import '../css/modal/Component.css'
import  'react-awesome-button/dist/themes/theme-rickiest.css';
import Variables from "./Variables";

export class WeAreClosed extends Component {
    variables = Variables;
    constructor(props){
        super(props);
        this.state = {
            showModal: true
        };
    }
    componentWillMount(){        
        var nextEventDate = new Date(this.variables.brandStartTime.replace(' ', 'T'))
        var todaysDate = new Date();
        var hour = nextEventDate.getHours()
        var day = nextEventDate.getDay()
        var dayName = "Sunday";
        var amorPm = "am"
        if(hour > 12){
            hour = hour - 12
            amorPm = "pm"
        }

        switch(day){
            case 1:
                dayName = "Monday"
                break;
            case 2:
                dayName = "Tuesday"
                break;
            case 3:
                dayName = "Wednessday"
                break;
            case 4:
                dayName = "Thursday"
                break;
            case 5:
                dayName = "Friday"
                break;
            case 6:
                dayName = "Saturday"
                break;
            default:
                break;
            
        }

        if(nextEventDate.getFullYear === todaysDate.getFullYear && 
            nextEventDate.getMonth() === todaysDate.getMonth() && 
            nextEventDate.getDate() === todaysDate.getDate()){
            this.variables.brandStartTime = " at " + hour + amorPm;
        }else{
            this.variables.brandStartTime = " on " + dayName + " at " + hour + amorPm;
        }
    }
    closePopup = () => {
        this.setState({
            showModal: false
        })
        setTimeout(() => {
            this.props.onChange(0)
        }, 1000);
    }
  render() {
    return (
        <div>
          <div className={(this.state.showModal)? "md-modal md-effect-8 md-show" : "md-modal md-effect-8"}
                      id="subscribeModal">
                  <div className="md-content">
                      <div>
                          <p>Sorry! we've closed check back {this.variables.brandStartTime}</p>
                          <div className="center-div mt20 option">
                              <span className="cur-hand" onClick={this.closePopup}>Close</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="md-overlay"></div>
        </div>
      )
  }
}

export default WeAreClosed
