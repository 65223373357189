import React, { Component } from 'react';
import Question from './Components/Question';
import CountDown from './Components/CountDown';
import EnterMobileNo from './Components/EnterMobileNo';
import YouLost from './Components/YouLost';
import YouWon from './Components/YouWon';
import Variables from "./Components/Variables";
import './App.css';
import Processing from './Components/Processing';
import cookie from 'react-cookies';
import Start2 from './Components/Start2';

class App extends Component {
  variables = Variables;
  constructor(props) {
    super(props);
    
    this.state = {
      begin: 7
    };

    this.variables.brand = cookie.load('_brand');
  }

  componentWillMount(){
    this.setState({
      begin: 1
    });
  }

  changeHandler = (value) => {
    this.setState({
        begin: value
    });
  }

  render() {
    const begin = this.state.begin;
    if (begin === 1) {
      return (
        <div className="App">
          <Start2 onChange={this.changeHandler} />
        </div>
      );
    }else if (begin === 2) {
      return (
        <div className="App">
          <EnterMobileNo onChange={this.changeHandler}  />
        </div>
      );
    }else if (begin === 3) {
      return (
        <div className="App">
          <CountDown onChange={this.changeHandler} />
        </div>
      );
    }else if (begin === 4) {
      return (
        <div className="App">
          <Question onChange={this.changeHandler} />
        </div>
      );
    }else if (begin === 5) {
      return (
        <div className="App">
          <YouLost onChange={this.changeHandler} />
        </div>
      );
    }else if (begin === 6) {
      return (
        <div className="App">
          <YouWon onChange={this.changeHandler} />
        </div>
      );
    }else if (begin === 7) {
      return (
        <div className="App">
          <Processing visible={true} />
        </div>
      );
    }
    else {
      return (
        <div className="App">
        </div>
      );
    }
  }
}

export default App;
