import React, { Component } from 'react'
import '../css/modal/Component.css'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
import { setTimeout } from 'timers';
import Variables from "./Variables";

export class PopupSubscribe extends Component {
    variables = Variables;
    constructor(props){
        super(props);
        this.state = {
            subscriptionSent: false,
            showModal: true,
            processing: false,
            SelectNetwork: false,
            otpstate: 0,
            otperror: ""
        };
    }

    ClosePopup = () => {
        this.setState({
            showModal: false
        })

        setTimeout(() => {
            if(this.state.subscriptionSent === false){
                this.props.onChange(0)
                return;
            }
            if(this.variables.network === "mtn"){
                this.props.onChange(2)
            }else{
                this.props.onChange(0)
            }
        }, 1000);
    }

    SendOTP(mobileNo){
        var url = this.variables.apiBaseUrl + 'initiateOTP/' + mobileNo

        fetch(url, {mode: 'cors'})
            .then(results => {
            return results.json();
            }).then(data => {
                this.setState({
                    processing: false
                })
                let ele = document.getElementById("optin-confirm-div")
                ele.classList.add("hide");
                if(data.hasOwnProperty('success')){
                    this.HideSelectNetwork()
                    this.setState({
                        otpstate: 1
                    });
                }
            })
    }

    VerifyOTP(mobileNo, otpCode) {
        var url = this.variables.apiBaseUrl + 'verifyOTP'

        this.setState({
            otperror: ""
        });
        let dataToSubmit = {
            mobileNo: mobileNo,
            otpCode: otpCode
        }
        fetch(url, 
            {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify(dataToSubmit),
            })
            .then(results => {
            return results.json();
            }).then(data => {
                this.setState({
                    processing: false
                })
                if(data.hasOwnProperty('success')){
                    this.HideSelectNetwork()
                    this.setState({
                        otpstate: 2
                    });
                    this.SubscribeMobileNo()
                }else{
                    this.setState({
                        otperror: data.error
                    });
                }
            })
    }

    SubscribeMobileNo = () => {
        this.setState({
            processing: true
        })
        
        var mobileNo = '%2b233' + this.variables.mobileNo.substr(1, this.variables.mobileNo.length);
        var network = this.variables.network;
        if(this.state.otpstate === 0){
            this.SendOTP(mobileNo)
            return;
        }
        else if(this.state.otpstate === 1){
            let otpCode = document.getElementById("txOtpcode").value;
            this.VerifyOTP(mobileNo, otpCode)
            return;
        }

        var url = this.variables.subscriptionApiBase + 'subscribe/' + mobileNo + "/" + this.variables.brand.name
        if(this.variables.selectNetwork){
            url = this.variables.subscriptionApiBase + 'subscribe/' + network + '/' + mobileNo + this.variables.brand.name
        }

            fetch(url, {mode: 'cors'})
                .then(results => {
                return results.json();
                }).then(data => {
                    this.variables.continuePlay = true
                    this.ClosePopup(true);
                })
    }

    SelectNetwork = () => {
        if(this.variables.selectNetwork){
            this.setState({
                SelectNetwork: true
            })
        }else{
            this.SubscribeMobileNo()
        }
    }

    HideInitOptIn = (e) =>{
        e.target.classList.add("hide");
        let ele = document.getElementById("select-network-div")
        ele.classList.remove("hide");
        ele.classList.add("fadeinanime");
    }

    HideSelectNetwork(){
        let ele = document.getElementById("select-network-div")
        ele.classList.add("hide");

        let ele2 = document.getElementById("enter-otp-div")
        ele2.classList.remove("hide");
    }

    SetNetwork = (e) => {
        let network = e.target.innerText.trim();
        this.variables.network = network.toLowerCase();
        let networks = document.getElementsByClassName("select-network");
        for (let item of networks) {
            item.classList.remove("selected");
        }
        e.target.classList.add("selected");

        let dvcontinue = document.getElementById("continue-div")
        dvcontinue.classList.remove("hide");
        dvcontinue.classList.add("fadeinanime");
    }

  render() {
    return (
      <div>
        <div className={(this.state.showModal)? "md-modal md-effect-8 md-show" : "md-modal md-effect-8"}
            id="subscribeModal">
                <div className="md-content">
                    <div id="optin-confirm-div" 
                        className={this.state.SelectNetwork === true ? "fadeoutanime" : ""} onAnimationEnd={this.HideInitOptIn}>
                        <p><b>{this.variables.mobileNo}</b> is currently not subscribed to BrainWave service. Tap the Opt In button to join.</p>
                        <div className="center-div mt20 option">
                            <span className="cur-hand" onClick={this.ClosePopup}>Not Now</span>
                            {!this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" 
                                type="secondary" action={this.SelectNetwork}>Opt In</AwesomeButton>
                            }
                            {this.state.processing &&
                                <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" disabled >Procesing...</AwesomeButton>
                            }

                        </div>
                    </div>
                    <div id="select-network-div" className="hide">
                        <p className="center-div white mt20"><b>Select network for the number {this.variables.mobileNo}</b></p>
                        <div className="center-div clear-fix table mt10">
                            <div className="cur-hand table select-network float-left" onClick={this.SetNetwork}>MTN</div>
                            <div className="cur-hand table select-network" onClick={this.SetNetwork}>Vodafone</div>
                        </div>

                        <div className="center-div mt25 option hide" id="continue-div">
                        {!this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" 
                                action={this.SubscribeMobileNo}>Continue</AwesomeButton>
                        }
                        {this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" disabled >Procesing...</AwesomeButton>
                        }

                        </div>

                        <p className="text-center mt20 text14 hide"><small>AirtelTigo and Glo are not not supported</small></p>
                    </div>
                    <div id="enter-otp-div" className="hide">
                        <p className="center-div white mt20"><b>Enter the code sent to {this.variables.mobileNo} below</b></p>
                        <div className="center-div clear-fix table mt10">
                            <input type="text" id="txOtpcode" className="text20 text-center full-width" />
                        </div>

                        {this.state.otperror.length > 0 && 
                            <p className="center-div red">{this.state.otperror}</p>
                        }
                        <div className="center-div mt25 option" id="continue-div">
                        {!this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" 
                                action={this.SubscribeMobileNo}>Continue</AwesomeButton>
                        }
                        {this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" disabled >Procesing...</AwesomeButton>
                        }

                        </div>

                        <p className="text-center mt20 text14 hide"><small>AirtelTigo and Glo are not not supported</small></p>
                    </div>
                </div>
            </div>
            <div className="md-overlay"></div>
      </div>
    )
  }
}

export default PopupSubscribe
