import React, { Component } from 'react'
import Variables from './Variables';
import cookie from 'react-cookies';

export class Start2 extends Component {
  variables = Variables;

  StartPlaying = (e) => {
    this.props.onChange(2);
  } 

  state = {
    processing: false
  }

  componentDidMount() {
    this.variables.mobileNo = cookie.load('bw_brand_user');
    if(typeof(this.variables.mobileNo) === "undefined"){
      this.variables.mobileNo = "";
    }
    var apiKey = document.location.hash.replace("#", "");
    this.variables.cookiedata = cookie.load('_btd');
    apiKey = (apiKey === "") ? this.variables.brandApiKey : apiKey;
    this.setState({
      processing: true
    });
    fetch(this.variables.apiBaseUrl + 'getBrand/' + apiKey, {
        mode: 'cors'
      }).then(results => {
        return results.json();
      }).then(data => {
        this.variables.brand = data.brand
        this.variables.polls = data.polls
        var date = new Date()
        var midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

        this.variables.shareUrl = this.variables.shareUrl.replace("$$description$$", this.variables.brand.description).replace("$$url$$", this.variables.brand.url);
        this.variables.facebookShareUrl = this.variables.facebookShareUrl.replace("$$description$$", this.variables.brand.description).replace("$$url$$", this.variables.brand.url);
        this.variables.twitterShareUrl = this.variables.twitterShareUrl.replace("$$description$$", this.variables.brand.description).replace("$$url$$", this.variables.brand.url);

        cookie.save('_brand', this.variables.brand, { path: '/', expires: midnight })
        this.props.onChange(2);
      })
  }

  render() {
    return (
        <>
        </>
    )
  }
}

export default Start2
